import { Box, Flex, Grid, GridItem, Input, Spinner, Text } from '@chakra-ui/react'
import { parse } from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import CancelButton from '../../components/core/CancelButton'
import Card from '../../components/core/Card'
import AddProfileType from '../../components/core/ChargingProfileDetails/AddProfileType'
import AfterTrial from '../../components/core/ChargingProfileDetails/AfterTrial'
import AITrial from '../../components/core/ChargingProfileDetails/AITrial'
import CarServicesFee from '../../components/core/ChargingProfileDetails/CarServicesFee'
import CorporateBundles from '../../components/core/ChargingProfileDetails/CorporateBundles'
import EditeFeesData from '../../components/core/ChargingProfileDetails/EditeFeesData'
import FormEdit from '../../components/core/ChargingProfileDetails/FormEdit'
import UploadContractFiles from '../../components/core/ChargingProfileDetails/UploadContractFiles'
import VersionsList from '../../components/core/ChargingProfileDetails/VersionsList'
import Container from '../../components/core/Container'
import CustomerCorporateList from '../../components/core/CustomerCorporateList'
import Header from '../../components/core/Header'
import Reqiured from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import AutoCompleteControl from '../transactions/components/AutoCompleteControl'
import { CustomersDetailsData } from './ChargingProfileType'

const AddChargingProfile: React.FC = () => {
  const [bundles, setBundles] = useState<any>([])

  const maxImageSize = 1 * 1024 * 1024
  const { search } = useLocation()
  const { customerId } = parse(search)
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    watch,
    setValue,
    control,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()
  const type = watch('type')
  const ai_trial_enabled = watch('ai_trial_enable')
  const ai_trial_number_days = watch('ai_trial_number_days')
  const bundle_type = watch('bundle_type')
  const postTrialPath = watch('post_trial_path')
  const selectedCustomer = watch('customer_id')
  const [isCompressing, setIsCompressing] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<any[]>([])
  const [lastversionData, setLastVersionData] = useState<any>([])
  const [customerData, setCustomerData] = useState<CustomersDetailsData | null>(null)

  const [customerHasCharginge, setCustomerHasCharginge] = useState<boolean>(true)

  const [durationOptions, setDurationOptions] = useState<any[]>([
    {
      name: dictionary().START_DATE,
      type: 'date',
      register: register?.('starts_at', {
        required: dictionary().REQUIRED,
      }),
    },
  ])

  useEffect(() => {
    if (customerData && customerData?.settlement === 'PRE_PAID') {
      setValue('finance_rate', 0)
    }
  }, [customerData])

  useEffect(() => {
    selectedCustomer?.length > 0 ? mutateIsCustomerHasCharging() : setCustomerHasCharginge(true)
    if (selectedCustomer?.length > 0) {
      mutateIsCustomerHasCharging()
      getLastChargingProfile()
    } else {
      setCustomerHasCharginge(true)
      setValue('type', 'CHARGED')
    }
  }, [selectedCustomer])

  const setZeroValues = () => {
    setValue('vehicle_subscription_fees', 0)
    setValue('nfc_fees', 0)
    setValue('installation_fees', 0)
    setValue('nfc_replacement_fees', 0)
    setValue('fee_rate', 0)
    setValue('internal_stations_fee_rate', 0)
    setValue('finance_rate', 0)
    setValue('tips_type', 'UNIT')
    setValue('tips_percentage', 0)
    setValue('fraud_detection_fees', 0)
    setValue('auto_refund', true)
    setValue('car_wash_fees', 0)
    setValue('oil_change_fees', 0)
    setValue('road_assistance_cost', 0)
    // setValue('billing_period', 'MONTHLY')
    delete errors.vehicle_subscription_fees
    delete errors.nfc_fees
    delete errors.installation_fees
    delete errors.nfc_replacement_fees
    delete errors.fee_rate
    delete errors.internal_stations_fee_rate
    delete errors.finance_rate
    delete errors.tips_type
    delete errors.tips_percentage
    delete errors.fraud_detection_fees
    delete errors.billing_period
    delete errors.road_assistance_cost
  }

  const { mutate: mutateCustomer, isLoading: isLoadingCustomer } = useMutation<CustomersDetailsData>(() => fetch('GET', `/customers/${selectedCustomer[0]}`), {
    onSuccess: (data): void => {
      setCustomerData(data)
    },
  })
  const { mutate: mutateIsCustomerHasCharging } = useMutation(() => fetch('GET', `/charging-profile/customer/${selectedCustomer[0]}/have-charged-profile`), {
    onSuccess: (data): void => {
      setCustomerHasCharginge(data)
      if (data === true) {
        setValue('type', 'CHARGED')
      }
    },
  })

  const { mutate: getLastChargingProfile } = useMutation(() => fetch('GET', `/charging-profile/customer/${selectedCustomer[0]}/latest-version`), {
    onSuccess: (data): void => {
      setLastVersionData(data)
      setValue('starts_at', data?.starts_at?.split('T')[0])
      setValue('expires_at', data?.expires_at?.split('T')[0])

      setValue('type', data?.type)
      data?.trial_duration_in_days && setValue('durationType', 'CUSTOM_DURATION')
      data?.trial_duration_in_days && setValue('trial_duration_in_days', data?.trial_duration_in_days)

      setValue('bundle_type', data?.bundle_id)
      setValue('ai_trial_enable', data?.ai_trial?.ai_trial_enable)
      setValue('ai_trial_number_days', data?.ai_trial?.ai_trial_number_days)
      setValue('enable_services', data?.enable_car_wash || data?.enable_oil_change)
      setValue('enable_car_wash', data?.enable_car_wash)
      setValue('enable_oil_change', data?.enable_oil_change)

      if (data?.type === 'FREE_TRIAL') {
        setZeroValues()
      } else {
        setValue('car_wash_fees', data?.car_wash_fees)
        setValue('oil_change_fees', data?.oil_change_fees)

        setValue('vehicle_subscription_fees', data?.vehicle_subscription_fees)
        setValue('nfc_fees', data?.nfc_fees)
        setValue('installation_fees', data?.installation_fees)
        setValue('nfc_replacement_fees', data?.nfc_replacement_fees)
        setValue('fee_rate', data?.fee_rate)
        setValue('internal_stations_fee_rate', data?.internal_stations_fee_rate)
        setValue('finance_rate', data?.finance_rate)
        setValue('tips_type', data?.tips_type)
        setValue('tips_percentage', data?.tips_percentage)
        setValue('fraud_detection_fees', data?.fraud_detection_fees)
      }

      setValue('billing_period', data?.billing_period)
      setValue('post_trial_path', data?.post_trial_path)
      setValue('contract_files', data?.files)
    },
    onError: (): void => {
      setLastVersionData([])
      setValue('starts_at', '')
      setValue('expires_at', '')

      setValue('type', '')
      setValue('durationType', '')
      setValue('trial_duration_in_days', '')
      setValue('bundle_type', '')
      setValue('ai_trial_enable', '')
      setValue('ai_trial_number_days', '')

      setValue('enable_services', '')
      setValue('enable_car_wash', '')
      setValue('car_wash_fees', '')
      setValue('enable_oil_change', '')
      setValue('oil_change_fees', '')

      setValue('vehicle_subscription_fees', '')
      setValue('nfc_fees', '')
      setValue('installation_fees', '')
      setValue('nfc_replacement_fees', '')
      setValue('fee_rate', '')
      setValue('internal_stations_fee_rate', '')
      setValue('tips_type', 'UNIT')
      setValue('tips_percentage', '')
      setValue('fraud_detection_fees', '')
      setValue('billing_period', '')
      setValue('post_trial_path', '')
      setValue('contract_files', '')
    },
  })

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/charging-profile', { data: vars }), {
    onSuccess: (): void => {
      push('/charging-profiles')
      onSuccess()
    },
  })

  const getAfterTrailProfile = (formData: any, payload: any) => {
    const temp: any = {}
    delete formData[`after_trial_profile`]
    Object.keys(formData).forEach((item: string) => {
      const keys = item.split('-')
      if (keys[0] === 'after_trial_profile') {
        temp[keys[1]] = formData[`after_trial_profile-${keys[1]}`]
        delete formData[`after_trial_profile-${keys[1]}`]
      }
    })
    const AfterTrailProfile = JSON.stringify(temp)
    payload.append(`after_trial_profile`, AfterTrailProfile)
  }

  const AddChargingProfile = async (formData: any) => {
    const isNotFirstChargingProfile = (customerId && customerHasCharginge) || (customerData && customerHasCharginge)
    const payload = new FormData()
    formData.customer_id = formData.customer_id[0]

    delete formData?.enable_services
    delete formData?.contract_files
    delete formData?.durationType
    isNotFirstChargingProfile && (formData.type = 'CHARGED')

    formData.type !== 'CHARGED' && !isNotFirstChargingProfile && delete formData.expires_at
    type === 'CHARGED' && delete formData.trial_duration_in_days
    formData.type === 'CHARGED' && delete formData.post_trial_path

    isNotFirstChargingProfile && delete formData.after_trial_profile
    isNotFirstChargingProfile && delete formData.post_trial_path

    getAfterTrailProfile(formData, payload)

    formData.bundle_id = bundle_type

    ai_trial_enabled === true ? (formData.ai_trial_enable = true) : (formData.ai_trial_enable = false)
    ai_trial_enabled === true && (formData.ai_trial_number_days = ai_trial_number_days)

    Object.keys(formData).forEach((key) => {
      key !== 'images' && payload.append(key, formData[key as keyof any])
    })

    if (selectedFiles.length < 1) {
      alert('الرجاء رفع ملفات العقد')
      return
    }

    if (selectedFiles.length > 0) {
      try {
        let fileLinkIndex = 0
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i]
          if (file?.type?.startsWith('image/')) {
            payload.append('images', file)
          } else if (file?.type?.startsWith('application/pdf')) {
            payload.append('pdfs', file)
          } else {
            payload.append(`file_links[${fileLinkIndex}]`, file)
            fileLinkIndex++
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        // setIsCompressing(false)
        mutate(payload)
      }
    }
  }

  useEffect(() => {
    if (selectedCustomer?.length > 0) {
      mutateCustomer()
    } else {
      setCustomerData(null)
    }
  }, [selectedCustomer])

  useEffect(() => {
    selectedFiles.length > 0 && trigger('contract_files')
  }, [selectedFiles])

  useEffect(() => {
    customerId && setValue('customer_id', [+customerId])
  }, [customerId])
  useEffect(() => {
    if ((customerId && customerHasCharginge) || (customerData && customerHasCharginge)) {
      setDurationOptions([
        {
          name: dictionary().START_DATE,
          type: 'date',
          register: register?.('starts_at', {
            required: dictionary().REQUIRED,
          }),
        },
        {
          name: dictionary().END_DATE,
          type: 'date',
          register: register?.('expires_at', {
            required: dictionary().REQUIRED,
          }),
        },
      ])
    } else {
      setDurationOptions([
        {
          name: dictionary().START_DATE,
          type: 'date',
          register: register?.('starts_at', {
            required: dictionary().REQUIRED,
          }),
        },
      ])
    }
  }, [customerId, customerData, customerHasCharginge])

  useEffect(() => {
    setValue('trial_duration_in_days', 15)
    setValue('auto_refund', true)
    getBundles()
  }, [])

  useEffect(() => {
    if (typeof (error as any)?.data?.errorDetails?.message === 'string') {
      ;(error as any)?.data?.errorDetails?.message && onError((error as any)?.data?.errorDetails?.message)
    } else {
      ;(error as any)?.data?.errorDetails?.message && onError((error as any)?.data?.errorDetails?.message[0])
    }
  }, [(error as any)?.data?.errorDetails?.message])

  useEffect(() => {
    setValue('post_trial_path', '')
    setValue('after_trial_profile', '')
    if (type === 'FREE_TRIAL') {
      setZeroValues()
    }
  }, [type])

  const showCancelConfirmationAlert = () => {
    // Display the confirmation dialog
    const userConfirmed = confirm('هل أنت متأكد من الإلغاء و مغادرة هذه الصفحة؟')

    // Check the user's response
    if (userConfirmed) {
      push('/charging-profiles')
    } else {
      return
    }
  }

  const {
    isLoading: isLoadingBundles,
    mutate: getBundles,
    error: errorBundles,
  } = useMutation(() => fetch('GET', '/corporate-bundle'), {
    onSuccess: (data: any): void => {
      // console.log('🚀 ~ file: add.tsx:285 ~ data:', data)
      setBundles(data)
      // onSuccess()
    },
  })

  return (
    <Container>
      <Box style={{ minHeight: '100vh', marginBottom: '20px' }}>
        <Flex ref={headerRef} className='margin-150' flexDir='column' mb={8}>
          <Header
            title={dictionary().ADD_NEW_CORPORATE}
            // action={}
          />
        </Flex>
        <Flex sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-45px', marginBottom: '30px' }}>
          <Text
            style={{
              color: 'rgba(111, 101, 219, 1)',
              fontFamily: 'Cairo',
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            إضافة ملف
          </Text>
        </Flex>
        <Grid templateColumns='repeat(12, 1fr)' gap={4}>
          <GridItem colSpan={!!customerId ? [12, 12, 9, 10] : [12]}>
            <form onSubmit={handleSubmit(AddChargingProfile as any)}>
              <Grid gap={4}>
                <Card p='5'>
                  <Grid templateColumns='repeat(12, 1fr)' gap={4} pb={4}>
                    <GridItem colSpan={[12]}>
                      <Text
                        style={{
                          fontFamily: 'Noto Kufi Arabic',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          textAlign: 'right',
                        }}
                        mb={5}
                        pb={5}
                        pr={2}
                        borderBottom='1px solid rgba(226, 232, 240, 1)'
                      >
                        بيانات أساسية
                      </Text>
                    </GridItem>
                    <GridItem colSpan={[12, 6, 4]}>
                      <Flex flexDirection={'column'}>
                        {customerData && customerId ? (
                          <>
                            <Text
                              style={{
                                fontFamily: 'Noto Kufi Arabic',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                textAlign: 'right',
                              }}
                              mb={2}
                              fontSize='16px'
                              fontWeight='600'
                            >
                              {dictionary().CUSTOMER}
                            </Text>
                            <Input
                              style={{
                                fontFamily: 'Noto Kufi Arabic',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                textAlign: 'right',
                              }}
                              type='text'
                              value={customerData?.name}
                              disabled
                            />
                          </>
                        ) : (
                          <AutoCompleteControl
                            width='100%'
                            control={control}
                            dataTest={dictionary().CORPORATE}
                            errors={errors}
                            identifier='customer_id'
                            label={dictionary().CUSTOMER}
                            route='/customers'
                            displayFormatter={(option: any) => option?.name}
                            boxProps={{
                              overflowY: 'auto',
                            }}
                            labelStyle={{ fontFamily: 'Noto Kufi Arabic', fontSize: '14px', fontWeight: 500, lineHeight: '20px', textAlign: 'right' }}
                          />
                        )}
                        {isLoadingCustomer ? (
                          <Flex h={'40px'} mt={2} w={'100%'} pr={'7%'} justifyContent={'flex-start'} alignItems={'center'}>
                            <Spinner size={'sm'} color={'#6F65DB'} />
                          </Flex>
                        ) : (
                          <CustomerCorporateList corporates={customerData?.corporates ?? []} maxDispkay={3} />
                        )}
                      </Flex>
                    </GridItem>

                    <GridItem colSpan={[12, 4]} mt={2}>
                      <Text
                        style={{
                          fontFamily: 'Noto Kufi Arabic',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          textAlign: 'right',
                        }}
                        color='#808080'
                        mb={2}
                        fontSize='16px'
                        fontWeight='600'
                      >
                        {dictionary().TAX_ID}
                      </Text>
                      {isLoadingCustomer ? (
                        <Flex h={'40px'} mt={2} w={'100%'} pr={'7%'} justifyContent={'flex-start'} alignItems={'center'}>
                          <Spinner size={'sm'} color={'#6F65DB'} />
                        </Flex>
                      ) : (
                        <CustomerCorporateList corporates={customerData?.corporates ?? []} maxDispkay={3} listOf={'tax_id_number'} />
                      )}
                    </GridItem>
                    <GridItem colSpan={[12, 2]} mt={2}>
                      <Text
                        style={{
                          fontFamily: 'Noto Kufi Arabic',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          textAlign: 'right',
                        }}
                        color='#808080'
                        mb={2}
                        fontSize='16px'
                        fontWeight='600'
                      >
                        {dictionary().SETTLEMENT_TYPE}
                      </Text>
                      {isLoadingCustomer ? (
                        <Flex h={'40px'} mt={2} w={'100%'} pr={'7%'} justifyContent={'flex-start'} alignItems={'center'}>
                          <Spinner size={'sm'} color={'#6F65DB'} />
                        </Flex>
                      ) : (
                        <>{dictionary()?.[customerData?.settlement as keyof typeof dictionary]}</>
                      )}
                    </GridItem>
                    {durationOptions.map((item: any, index: number) => (
                      <GridItem colSpan={[12, 4, 3]} mb={2} key={index}>
                        <Text
                          style={{
                            fontFamily: 'Noto Kufi Arabic',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            textAlign: 'right',
                          }}
                          mb={2}
                        >
                          <Text
                            style={{
                              fontFamily: 'Noto Kufi Arabic',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              textAlign: 'right',
                            }}
                            mb={2}
                          >
                            {item.name} <Reqiured />
                          </Text>
                          <FormEdit key={index} detail={item} control={control} errors={errors} />
                        </Text>
                      </GridItem>
                    ))}
                  </Grid>
                </Card>
                {!customerHasCharginge && (
                  <AddProfileType watch={watch} control={control} errors={errors} register={register} setValue={setValue} getValues={getValues} />
                )}
                {customerData && type && !isLoadingBundles && (
                  <CorporateBundles watch={watch} control={control} errors={errors} register={register} setValue={setValue} bundles={bundles} />
                )}
                {customerData && type && !isLoadingBundles && [1, 2].includes(bundle_type) && (
                  <AITrial watch={watch} control={control} errors={errors} register={register} setValue={setValue} bundles={bundles} getValues={getValues} />
                )}
                {customerData && (
                  <EditeFeesData
                    setValue={setValue}
                    getValues={getValues}
                    corporate={customerData}
                    enableEdit={true}
                    versionData={[]}
                    errors={errors}
                    control={control}
                    register={register}
                    disabled={type === 'FREE_TRIAL'}
                    watch={watch}
                  />
                )}
                {customerData && (
                  <CarServicesFee
                    disableFields={{
                      car_wash_fees: type === 'FREE_TRIAL',
                      oil_change_fees: type === 'FREE_TRIAL',
                    }}
                    watch={watch}
                    control={control}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                  />
                )}
                <UploadContractFiles
                  watch={watch}
                  register={register}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  errors={errors}
                  lastVersionFiles={lastversionData?.files || []}
                />
                {['FREE_TRIAL', 'PAID_TRIAL'].includes(type) && <AfterTrial control={control} errors={errors} />}
                {postTrialPath === 'AFTER' && (
                  <EditeFeesData
                    enableEdit={true}
                    versionData={[]}
                    errors={errors}
                    control={control}
                    register={register}
                    isAfterTrail={true}
                    getValues={getValues}
                    watch={watch}
                  />
                )}
              </Grid>
            </form>
          </GridItem>
          {!!customerId && (
            <GridItem colSpan={[12, 12, 3, 2]}>
              <VersionsList customerId={+customerId} corporateId={-1} />
            </GridItem>
          )}
        </Grid>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: '20px' }}>
          <CancelButton label='إلغاء' isLoading={isLoading || isCompressing} onClick={() => showCancelConfirmationAlert()} />
          <SaveButton label='إضافة' isLoading={isLoading || isCompressing} onClick={async () => (await trigger()) && AddChargingProfile(getValues() as any)} />
        </Box>
      </Box>
    </Container>
  )
}

export default AddChargingProfile
