import { SearchIcon } from '@chakra-ui/icons'
import { Box, Button, Divider, Flex, Grid, GridItem, Input, InputGroup, InputLeftElement, Progress, Text, useDisclosure } from '@chakra-ui/react'
import { parse, stringify } from 'query-string'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { getFilters } from '../../helpers/getFilters'
import { handleDownloadFile } from '../../helpers/handleDownloadFile'
import useAlert from '../../helpers/useAlert'
import useDebounce from '../../helpers/useDebounce'
import { SearchAndFilterProps } from '../../interfaces/viewAllProps'
import { useStore } from '../../store'
import { useFilterStore } from '../../stores/filterStore'
import { useNotificationStore } from '../../stores/pushNotificationStore'
import AdvancedFilter from './advanced-filters/AdvancedFilter'
import ChargingProfileFilterModal from './ChargingProfileFilterModal'
import CsvDownloader from './Download'
import Pagination from './Pagination'

export const Search = () => {
  const { push } = useHistory()
  const location = useLocation()
  const query = parse(location.search)
  const [searchTerm, setSearchTerm] = useState(query.searchTerm as string)

  const search = useDebounce((value: string) => {
    query.searchTerm = value
    delete query.page
    push({ search: stringify(query) })
  }, 900)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    search(e.target.value)
  }

  return (
    <InputGroup>
      <InputLeftElement height='100%' pointerEvents='none'>
        <SearchIcon color='gray.700' />
      </InputLeftElement>
      <Input
        pr={4}
        data-test='search'
        placeholder={dictionary().SEARCH}
        onChange={onChange}
        value={searchTerm ?? ''}
        type='text'
        size='lg'
        h={'40px'}
        focusBorderColor='blue.500'
      />
    </InputGroup>
  )
}

interface DownloadProgress {
  loaded: number
  total: number
}

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  headers,
  query,
  PAGE_SIZE,
  setPAGE_SIZE,
  hasSearch,
  totalCount,
  pageSize,
  restRoute,
  data,
  accounting = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const state = useFilterStore()
  const filters = getFilters(state)
  const { onError } = useAlert()
  const { initiatePusher, channel, bind, events, terminatePusher, pusher, enabled } = useNotificationStore()
  const [loading, setLoading] = useState(false)
  const [downloaded, setDownloaded] = useState<any>(0)
  const { userType } = useStore((state) => state)
  const userData = window.localStorage.getItem('activeRole')
  const userId = userData && JSON.parse(userData)?.id
  const pathsDoesnotHaveDownload = ['/fuel-transactions', '/charging-profiles', '/confirmed-payments', '/pos-devices']

  const onProgress = (progress: DownloadProgress) => {
    if (progress.loaded) {
      setDownloaded(Math.round(progress.loaded / 1024 / 1024).toFixed(2))
    }
  }
  useEffect(() => {
    if (channel !== null) {
      bind(events.RUNNING, () => {
        setLoading(true)
      })
      bind(events.FAILED, () => {
        terminatePusher()
        setLoading(false)
      })
      bind(events.SUCCESS, async (data: any) => {
        terminatePusher()
        await handleDownloadFile(import.meta.env.VITE_ANALYTICS_URL, `/utils/download/${data?.exported_file}`, data?.exported_file, onProgress)
        setLoading(false)
        setDownloaded(0)
      })
    }
  }, [channel])

  const { mutate } = useMutation((vars: any) => fetch('POST', `fuel-transactions/export`, { data: vars }), {
    onSuccess: (): void => {},
    onError,
  })

  const { mutate: exportCharginProfiles } = useMutation(() => fetch('GET', `charging-profile/export`, { query }), {
    onSuccess: async (data: any) => {
      await handleDownloadFile(import.meta.env.VITE_ANALYTICS_URL, `/utils/download/${data?.file_name}`, data?.file_name, () => {})
    },
    onError,
  })

  // useEffect(() => {
  //   const userTypeFormated = userType?.toUpperCase()
  //   initiatePusher(userTypeFormated, userId)
  // }, [])

  const { pathname } = useLocation()
  return (
    <Box mt={5}>
      <Grid templateColumns='repeat(5, 1fr)' gap={4} display={['inline-block', 'inline-block', 'inline-block', 'grid']}>
        <GridItem colSpan={3} display='flex' alignItems='center' mb={['10px ', '10px', '10px', '0']}>
          <AdvancedFilter />
          {hasSearch && !['/fuel-transactions', '/charging-profiles'].includes(pathname) && <Search />}
          {pathname === '/charging-profiles' && <ChargingProfileFilterModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
        </GridItem>
        <GridItem colSpan={2}>
          {pathname === '/fuel-transactions' && (
            <Flex align='center' justifyContent='space-between'>
              <Button
                onClick={() => {
                  if (!enabled) {
                    const userTypeFormated = userType?.toUpperCase()
                    initiatePusher(userType?.toUpperCase(), userId)
                    //settimeout
                    setTimeout(() => {
                      mutate(filters)
                    }, 1000)
                  } else {
                    mutate(filters)
                  }
                }}
              >
                {loading ? (
                  <div>
                    <Progress size='md' isIndeterminate />
                    <Text ml={2}>{downloaded + ' Mb'}</Text>
                  </div>
                ) : (
                  'تحميل CSV'
                )}
              </Button>

              <Divider orientation='vertical' height='42px' />
              <Pagination totalCount={totalCount} pageSize={pageSize} />
            </Flex>
          )}
          {((data && data?.data?.length > 0) || (data && data?.length) || (data && Object.keys(data).length)) && (
            <Flex align='center' justifyContent='space-between'>
              {!pathsDoesnotHaveDownload.includes(pathname) && (
                <CsvDownloader
                  query={query}
                  headers={headers}
                  PAGE_SIZE={PAGE_SIZE}
                  setPAGE_SIZE={setPAGE_SIZE}
                  restRoute={restRoute}
                  isUpload={true}
                  accounting={accounting}
                />
              )}

              {pathname === '/charging-profiles' && <Button onClick={() => exportCharginProfiles()}>تحميل CSV</Button>}

              <Divider orientation='vertical' height='42px' />
              <Pagination totalCount={totalCount} pageSize={pageSize} />
            </Flex>
          )}
        </GridItem>
      </Grid>
    </Box>
  )
}

export default SearchAndFilter
